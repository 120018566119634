export default {
  details: {
    title: "taha",
    tagLine: "Software Engineer",
    description: "Personal Website of taha",
    url: "",
  },
  assets: {
    avatar: "/images/common/avatar.jpg",
    favicon: "/images/common/favicon.svg",
  },
};
